/* .footerContainer{
    background-color:rgba(0, 0, 0,.8 );
height: 25vh;
width: 100vw;
display: flex;
justify-content: space-around;
align-items: center;
margin-top: 5vw;
color: #ffffff40;
}
.footerContainer img{
    background-color: white;
    
    /* Set a backdrop filter with blur effect */
    /* backdrop-filter: blur(10px); Adjust the blur value as needed */
    
    /* Set height and width */
    /* height: 3.5vw;
    width: 6vw; */
    /* filter: blur(1px); */
    /* box-shadow: 1px 2px 8px 5px rgba(256, 256, 256,.5 ); */
/* } */

/* .links {
    display: flex;
    margin: 2vw;
  
}
.navLink{
    padding-left: 1vw;
    padding-right: 1vw;
    text-decoration: none;
    color: #ffffff40;
}

.icons{
    display: flex;
    justify-content: space-around;
   
}
.MainIcons{
    background-color: transparent;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0,.1 );
} */

  


.footerContainer {
    background-color: rgba(0, 0, 0, .8);
    height: 25vh;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 5vw;
    color: #ffffff40;
    padding: 0 20px; /* Add padding for better spacing on smaller screens */
}

.footerContainer img {
    background-color: white;
    /* Set a backdrop filter with blur effect */
    backdrop-filter: blur(10px); /* Adjust the blur value as needed */
    /* Set height and width */
    height: 3.5vw;
    width: 6vw;
    /* filter: blur(1px); */
    box-shadow: 1px 2px 8px 5px rgba(256, 256, 256, .5);
}

.links {
    display: flex;
    margin: 2vw;
}

.navLink {
    padding-left: 1vw;
    padding-right: 1vw;
    text-decoration: none;
    color: #ffffff40;
}

.icons {
    display: flex;
    justify-content: space-around;
}

.MainIcons {
    background-color: transparent;
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, .1);
}

/* Media Queries */
@media (max-width: 768px) {
    .footerContainer {
        height: auto; /* Change height to auto for smaller screens */
        flex-direction: column; /* Change flex-direction to column for smaller screens */
        padding: 20px; /* Adjust padding for better spacing on smaller screens */
    }

    .footerContainer img {
        height: auto; /* Change height to auto for smaller screens */
        width: 80px; /* Adjust width for smaller screens */
    }

    .links {
        margin: 10px 0; /* Adjust margin for smaller screens */
    }
}
