

/* Contact Component CSS */

/* Main container */
.aboutMainContainer {
    display: flex;
    flex-direction: column;
    padding: 0 5vw; /* Adjust horizontal padding */
    color: rgba(0, 0, 0, 0.7);
}

/* Details container */
.details {
    display: flex;
    flex-wrap: wrap; /* Wrap items when screen size reduces */
    justify-content: space-between;
    margin-top: 4vw;
}

/* Individual address container */
.adress {
    flex: 1; /* Distribute space equally among address containers */
    max-width: 33%; /* Each address container takes up to 33% of the width */
    margin-right: 1vw; /* Adjust right margin */
}

/* Heading styles */
.adress h2 {
    color: #1ABC9C;
    font-weight: 500;
    margin-bottom: 0.5vw; /* Adjust bottom margin */
}

/* Contact section styles */
.contact {
    color: rgba(0, 0, 0, 0.6);
}

/* Media Query for small screens */
@media screen and (max-width: 768px) {
    .aboutMainContainer {
        padding: 0 2vw; /* Adjust padding for smaller screens */
     
    }

    .details {
        flex-direction: column; /* Display details in a column on smaller screens */
    }

    .adress {
        max-width: 100%; /* Each address container takes full width on smaller screens */
        margin-right: 0; /* Remove right margin */
        margin-bottom: 2vw; /* Adjust bottom margin */
    }
}
