.h1{
    text-align: center;
}
.midLogo {
    height: 10vw;
    width: 11vw;
    margin-top: 20px;
}

.textContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.BTNLinks {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap to next line on smaller screens */
    justify-content: center;
    width: 100%; /* Adjust the width to fill the container */
    margin: 20px auto; /* Center the buttons and provide vertical margin */
}

.BTNLinks button {
    margin: 10px; /* Adjust the margin between buttons */
}

.aboutUs {
    text-align: center;
    margin-top: 5vw;
    margin-bottom: 2vw;
}

.mainBox {
    display: flex;
    flex-wrap: wrap; /* Allow boxes to wrap to next line on smaller screens */
    justify-content: space-around;
    color: #eeeeee;
    font: 14px Lato, sans-serif;
    padding: 20px; /* Add padding for better spacing */
}

.box {
    width: 30%; /* Adjust the width of each box for larger screens */
    flex-grow: 1; /* Allow boxes to grow to fill the space */
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    text-align: center;
    margin: 3px;
}

.box button {
    padding: 10px; /* Adjust button padding */
    font-weight: bold;
    background-color: transparent;
    border: 2px solid white;
    color: #eeeeee;
}

.buttonLinks {
    text-decoration: none;
    border: 1px solid black;
    margin: 10px;
    padding: 10px; /* Adjust link padding */
}

.box button:hover {
    background-color: #eeeeee;
    color: black;
    cursor: pointer;
}

/* Media Queries */
@media (max-width: 768px) {
    .mainBox .box {
        width: 90%; /* Adjust the width of each box for smaller screens */
    }
   
}
