/* Header Component CSS */

/* Styles for the container of the header */
.homeContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.5);
    padding: 10px 20px; /* Adjust padding as needed */
}

.homeLinkContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40vw; /* Adjust width of link container */
    height: 10vh;
    margin: 1.5vw; /* Adjust margins as needed */
}

.homeLogo {
    height: 8vw;
    width: 8vw;
}

.navLinks {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 700;
}

.navLinks.active {
    color: #1ABC9C; /* Active link color */
}

.navLinks:hover {
    color: #1ABC9C; /* Hovered link color */
}

/* Media Query for small screens */
@media screen and (max-width: 768px) {
    .homeLinkContainer {
        width: auto; /* Adjust width to auto for responsiveness */
        margin: 10px; /* Adjust margins as needed */
    }

    .navLinks {
        font-size: 14px; /* Adjust font size for smaller screens */
        margin: 1px;
    }
}









